"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mockXHR = mockXHR;
exports.default = void 0;

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

var _toConsumableArray2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _mockjs = _interopRequireDefault(require("mockjs"));

var _utils = require("../src/utils");

var _user = _interopRequireDefault(require("./user"));

var _role = _interopRequireDefault(require("./role"));

var _article = _interopRequireDefault(require("./article"));

var _remoteSearch = _interopRequireDefault(require("./remote-search"));

var mocks = [].concat((0, _toConsumableArray2.default)(_user.default), (0, _toConsumableArray2.default)(_role.default), (0, _toConsumableArray2.default)(_article.default), (0, _toConsumableArray2.default)(_remoteSearch.default)); // for front mock
// please use it cautiously, it will redefine XMLHttpRequest,
// which will cause many of your third-party libraries to be invalidated(like progress event).

function mockXHR() {
  // mock patch
  // https://github.com/nuysoft/Mock/issues/300
  _mockjs.default.XHR.prototype.proxy_send = _mockjs.default.XHR.prototype.send;

  _mockjs.default.XHR.prototype.send = function () {
    if (this.custom.xhr) {
      this.custom.xhr.withCredentials = this.withCredentials || false;

      if (this.responseType) {
        this.custom.xhr.responseType = this.responseType;
      }
    }

    this.proxy_send.apply(this, arguments);
  };

  function XHR2ExpressReqWrap(respond) {
    return function (options) {
      var result = null;

      if (respond instanceof Function) {
        var body = options.body,
            type = options.type,
            url = options.url; // https://expressjs.com/en/4x/api.html#req

        result = respond({
          method: type,
          body: JSON.parse(body),
          query: (0, _utils.param2Obj)(url)
        });
      } else {
        result = respond;
      }

      return _mockjs.default.mock(result);
    };
  }

  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = mocks[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var i = _step.value;

      _mockjs.default.mock(new RegExp(i.url), i.type || 'get', XHR2ExpressReqWrap(i.response));
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
} // for mock server


var responseFake = function responseFake(url, type, respond) {
  return {
    url: new RegExp("/mock".concat(url)),
    type: type || 'get',
    response: function response(req, res) {
      res.json(_mockjs.default.mock(respond instanceof Function ? respond(req, res) : respond));
    }
  };
};

var d = mocks.map(function (route) {
  return responseFake(route.url, route.type, route.response);
});
var _default = d;
exports.default = _default;